import React, { useRef, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import { motion, animate, useTransform, useScroll } from "framer-motion"
import { useInView } from "react-intersection-observer"

import Layout from "components/layout"
import SEO from "components/seo"
import FeaturedContent from "components/sections/featured-content"
import FeaturedTestimonial from "components/sections/testimonial"
import FeaturedVideo from "components/sections/featured-video"
import FeaturedWhitepaper from "components/sections/featured-whitepaper"
import TVDemand from "components/sections/tv-demand"
import LightSlantedSection from "components/sections/light-slant"
import ArticleShowcase from "components/sections/article-showcase"
import PartnerLogos from "components/partner-logos"
import Takeover from "components/sections/takeover"
import Button from "components/button"
import Quotes from "components/sections/quotes"
import Demand360Globe from "components/sections/demand360-globe"
import Demand360Ad from "components/demand360-ad"
import SlantedSection from "components/sections/slant"
import LogoStacked from "images/logo-stacked.svg"
import isBrowser from "utils/is-browser"
import { getSharePreviewUrls } from "utils/share-preview"
import MouseScroll from "images/icons/mouse-scroll.png"
import VideoModal from "components/sections/video-modal"
import StyledSlantedSection from "components/sections/styled-slant"
import ContentValuation from "components/sections/content-valuation-ad"
import ProductCards from "components/product-cards"
import BgWaves from "images/bg/waves-purple.jpg"
import FeaturedPressCarousel from "components/featured-press-carousel"
import TakeoverVideoModal from "components/sections/takeover-video-modal";

const PageBackgroundSection = styled.div`
  ${tw`relative`}

  background: linear-gradient(180deg, #000000 2%, rgba(0, 0, 0, 0) 4%),
    ${({ bg }) => `url(${bg})`};
  background-repeat: repeat-y;
  background-size: 100%;

  @media (max-width: 640px) {
    background-size: 250% !important;
    background-position: 30% 50% !important;
  }

  .header-section {
    ${tw`mx-auto pt-24 m:pt-48 l:pt-32 flex flex-col`}

    &.takeover {
      padding-top: 0px;

      @media (min-width: 1024px) {
        margin-top: -100px;
      }
    }
  }
`

const GlobeBackground = styled.div`
  ${tw`pt-32 l:pt-48`}

  background-color: black;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #000000 20.79%,
    #000000 74.78%,
    rgba(0, 0, 0, 0) 100%
  );
`

const LargeTitle = styled.h2`
  ${tw`py-2 w-full font-display font-bold text-white text-center`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }

  &.second-fold {
    @media (min-width: 1024px) {
      font-size: 44px;
      line-height: 55px;
    }

    @media (min-width: 1200px) {
      max-width: 900px;
    }
  }
`

const TakeOverLargeTitle = styled.h2`
  ${tw`py-2 w-full font-display font-bold text-springgreen text-center`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }

  &.second-fold {
    @media (min-width: 1024px) {
      font-size: 44px;
      line-height: 55px;
    }

    @media (min-width: 1200px) {
      max-width: 900px;
    }
  }
`

const Paragraph = styled.div`
  ${tw`font-body text-white`}
  font-size: 13px;
  line-height: 25px;
  @media (min-width: 640px) {
    font-size: 17px;
    line-height: 27px;
  }
`

const IntroTitle = styled.div`
  ${tw`font-body text-priority-darkP2 text-sm m:text-base l:text-4xl text-center`}
  line-height: 25px;
  @media (min-width: 640px) {
    font-size: 20px;
    line-height: 27px;
  }

  @media (min-width: 1024px) {
    font-size: 35px;
  }
`


const NewsPressArticles = styled.div`
  .press-title {
    ${tw`pt-0`}

    h2 {
      ${tw`text-white`}
    }
  }
`

const Header = styled.div`
  ${tw`relative overflow-hidden h-screen w-full`}

  .header-image {
    ${tw`relative w-full h-full`}

    transform: scale(1.1);

    figure {
      ${tw`absolute top-0 left-0 w-full h-full m-0`}
    }

    .signs-final {
      ${tw`opacity-0`}
    }

    .signs {
      ${tw`opacity-0`}
    }

    .city {
      ${tw`opacity-50`}
    }

    img {
      ${tw`w-full h-full object-cover`}

      object-fit: cover;
    }

    .overlay {
      ${tw`relative h-full w-full`}

      background: linear-gradient(
        180deg, rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 33%,
        rgba(0, 0, 0, 0.6) 66%,
        #000000 100%
      );
    }

    &.animate {
      animation: zoomout 20s forwards;

      @keyframes zoomout {
        0% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(1);
        }
      }

      .signs-final {
        animation: signs-final-fade 20s forwards;
      }

      @keyframes signs-final-fade {
        0% {
          opacity: 0;
        }
        70% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .signs {
        ${tw`opacity-0`}

        mask-image: radial-gradient(circle, #fff 0%, transparent 20%);
        mask-size: 100% 100%;
        mask-position: center center;
        mask-repeat: no-repeat;
        animation: clip-fade 20s forwards;
      }

      @keyframes clip-fade {
        0% {
          opacity: 0;
        }
        5% {
          opacity: 0;
          mask-size: 100% 100%;
        }
        10% {
          opacity: 1;
        }
        100% {
          mask-size: 2000% 2000%;
          opacity: 1;
        }
      }

      .city {
        ${tw`opacity-50`}

        animation: city 20s forwards;
      }

      @keyframes city {
        0% {
          opacity: 0.5;
        }
        30% {
          opacity: 1;
        }
      }
    }
  }

  .logo {
    ${tw`absolute flex flex-col h-full`}

    z-index: 1;
    left: 0;
    right: 0;

    top: calc(50% - 75px);

    @media (min-width: 640px) {
      top: calc(50% - 95px);
    }

    @media (min-width: 1024px) {
      top: calc(50% - 115px);
    }

    img {
      ${tw`mx-auto`}

      width: 150px;

      @media (min-width: 640px) {
        width: 190px;
      }

      @media (min-width: 1024px) {
        width: 230px;
      }
    }

    h1 {
      ${tw`text-center uppercase font-extrabold text-yellow mt-6 l:mt-10 text-xl m:text-2xl l:text-3xl`}

      line-height: 33px;
      letter-spacing: 5px;

      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
        0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1),
        0px 4px 4px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.1);

      @media (min-width: 640px) {
        line-height: 42px;
        letter-spacing: 7px;
      }

      @media (min-width: 1024px) {
        line-height: 50px;
        letter-spacing: 10px;
      }

      br {
        @media (min-width: 1024px) {
          ${tw`hidden`}
        }
      }
    }

    .mouse-scroll {
      ${tw`hidden m:block mx-auto mt-16 flex-grow relative`}

      img {
        ${tw`opacity-50`}

        height: 59px;
        width: 20px;
      }
    }
  }

  &.with-takeover {
    .header-image {
      .overlay {
        background: linear-gradient(
          180deg,
          #000000 0%,
          rgba(0, 0, 0, 0) 31.44%,
          rgba(0, 0, 0, 0.6) 49.11%,
          #000000 100%
        );
      }
    }
  }
`

const ActionContainer = styled.div`
  ${tw`flex flex-col pt-10 m:pt-16 justify-center items-center`}
`
const InfoSection = ({ children, id, className, contentBlock }) => (
  <div className={className} id={id}>
    <LargeTitle style={{ textAlign: "center" }}>
      {contentBlock.title}
    </LargeTitle>
    <Paragraph className="pt-6 pb-16 l:px-40 m:px-16">
      <div
        className="text-yellow font-body text-sm m:text-base l:text-xl text-center"
        dangerouslySetInnerHTML={{
          __html: contentBlock.headerContent?.html,
        }}
      />
    </Paragraph>
    {children}
  </div>
)

const TakeOverInfoSection = ({ children, id, className, contentBlock }) => (
    <div className={className} id={id}>
      <IntroTitle style={{ textAlign: "center" }}>
        Introducing
      </IntroTitle>
      <TakeOverLargeTitle style={{ textAlign: "center" }}>
        Streaming Metrics
      </TakeOverLargeTitle>
      <Paragraph className="pt-6 pb-6 l:px-38 m:px-16">
        <div
            className="text-white font-body text-sm m:text-base l:text-xl text-center"
            dangerouslySetInnerHTML={{
              __html: contentBlock.headerContent?.html,
            }}
        />
      </Paragraph>
      {children}
    </div>
)

const Home = ({ data }) => {
  const { gcms } = data
  const { page } = gcms

  const headerRef = useRef()
  const previousScrollY = useRef(isBrowser ? window.pageYOffset : 0)
  const direction = useRef(null)
  const scrolling = useRef(false)

  const [headerFrameRef, headerFrameInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const featuredWhitepapers = page.contentBlocks.filter((b) =>
    b.identifier?.endsWith("-whitepaper")
  )

  const featuredContent = page.contentBlocks.filter(
    (b) => b.identifier === "featured"
  )

  // const takeover = page.contentBlocks.find(
  //   (b) => b.identifier === "content-valuation-takeover"
  // )

  const takeover = page.contentBlocks.find(
      (b) => b.identifier === "streaming-metrics-takeover"
  )

  const homepageQuote = page.contentBlocks.find(
    (b) => b.identifier === "homepage-quote"
  )

  const exploreOurProducts = page.contentBlocks.find(
    (b) => b.identifier === "explore-our-products-section"
  )

  const demandDrivesMonetization = page.contentBlocks.find(
    (b) => b.identifier === "demand-drives-monetization-section"
  )

  let {
    start,
    end,
    title = "Welcome to\nthe attention economy",
  } = page.extraData?.header

  title = title.replace("\n", "&nbsp;<br />")

  const [startAnimation, setStartAnimation] = useState(!takeover)

  // staggered fade in animations
  const headerList = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 1,
        duration: 0,
        delay: 0,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        duration: 1.5,
      },
    },
  }

  const headerItem = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1.5, ease: "easeOut" },
    },
    hidden: { opacity: 0, y: 30 },
  }

  const mouseScrollItem = {
    visible: {
      opacity: 1,
      transition: { delay: 5.5, duration: 0.4, ease: "easeOut" },
    },
    hidden: { opacity: 0 },
  }

  const { scrollY } = useScroll()
  const y1 = useTransform(scrollY, [0, 500], [0, -160])
  const y2 = useTransform(scrollY, [0, 500], [0, -120])
  const y3 = useTransform(scrollY, [0, 500], [0, -80])

  function headerIsVisible() {
    if (!headerRef.current) {
      return null
    }

    const { height, top } = headerRef.current.getBoundingClientRect()

    return height - top * -1 > 0
  }

  let scrollTimer = null

  function handleScroll() {
    if (takeover) {
      // Do nothing when 'takeover' section is present
      return
    }

    clearTimeout(scrollTimer)

    scrollTimer = setTimeout(() => {
      if (headerIsVisible() && !scrolling.current) {
        const { height, top } = headerRef.current.getBoundingClientRect()

        if (top !== 0) {
          const scrollY = direction.current === "down" ? height : 0

          scrollTo(scrollY)
        }
      }
    }, 125)

    direction.current =
      previousScrollY.current < window.pageYOffset ? "down" : "up"
    previousScrollY.current = window.pageYOffset
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)

    return () => document.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (headerFrameInView) {
      setStartAnimation(true)
    }
  }, [headerFrameInView])

  function scrollTo(to) {
    const { height } = headerRef.current.getBoundingClientRect()

    animate(window.pageYOffset, to, {
      duration: 1.2 * (Math.abs(window.pageYOffset - to) / height),
      ease: "easeInOut",
      onUpdate: (v) => {
        window.scrollTo({ top: v })
      },
      onPlay: () => {
        scrolling.current = true
      },
      onComplete: () => {
        scrolling.current = false
      },
    })
  }

  return (
    <Layout>
      <Helmet>
        <script type="text/javascript" id="geoip" src="/js/geo.js"></script>
      </Helmet>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      {/*{!!takeover && <Takeover content={takeover} />}*/}
      {takeover && (
          <TakeOverInfoSection
              className="px-2 pt-8 m:pt-12 l:pt-16 mt-16 mx-auto m:max-w-m l:max-w-l"
              contentBlock={takeover}
          >
            <TakeoverVideoModal
                autoplayHash="streaming-play"
                className="mt-4 relative"
                data={takeover.extraData}
            />
            <ActionContainer>
              {takeover.linkUrl && (
                  <Button to={takeover.linkUrl}>{takeover.callToAction}</Button>
              )}
            </ActionContainer>
          </TakeOverInfoSection>
      )}
      {homepageQuote && (
          <Quotes quote={homepageQuote.mainContent?.text || ""} />
      )}
      {start && end && (
        <Header ref={headerRef} className={takeover ? "with-takeover" : ""}>
          <div
            className={`header-image ${startAnimation ? "animate" : ""}`}
            ref={headerFrameRef}
          >
            <figure className="city">
              <img src={start} alt="" />
            </figure>
            <figure className="signs">
              <img src={end} alt="" />
            </figure>
            <figure className="signs-final">
              <img src={end} alt="" />
            </figure>
            <div className="overlay" />
          </div>
          {!takeover && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={headerList}
              className="logo"
            >
              <motion.img
                variants={headerItem}
                src={LogoStacked}
                alt="logo"
                style={{ y: y1 }}
              />
              <motion.h1
                variants={headerItem}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
                style={{ y: y2 }}
              />
              <motion.div
                className="mouse-scroll"
                variants={mouseScrollItem}
                style={{ y: y3 }}
              >
                <motion.img
                  src={MouseScroll}
                  animate={{ y: [10, -10, 10] }}
                  transition={{
                    duration: 2,
                    ease: "easeInOut",
                    repeatType: "loop",
                    repeat: Infinity,
                    repeatDelay: 1,
                  }}
                />
              </motion.div>
            </motion.div>
          )}
          {takeover && (
            <div className="logo">
              <img src={LogoStacked} alt="logo" />
              <h1
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </div>
          )}
        </Header>
      )}

      <PageBackgroundSection bg={BgWaves} className="-mb-64">
        <div className={`header-section ${takeover ? "takeover" : ""}`}>
          <div className="mx-auto m:max-w-m l:max-w-l text-center">
            <LargeTitle className="second-fold" style={{ textAlign: "center" }}>
              {page.headerContent?.text}
            </LargeTitle>
          </div>
          <div className="mx-auto w-full l:max-w-l">
            {page?.featuredLogosClients && (
              <div className="pt-4">
                <PartnerLogos
                  data={page.featuredLogosClients}
                  tabletFull={true}
                  home={true}
                />
              </div>
            )}

          </div>
        </div>

        <StyledSlantedSection className="z-10 pt-16 l:pt-32 content-valuation">
          <ContentValuation />
        </StyledSlantedSection>

        {demandDrivesMonetization && (
          <InfoSection
            className="px-2 pt-16 m:pt-24 l:pt-32 mt-16 mx-auto m:max-w-m l:max-w-l"
            contentBlock={demandDrivesMonetization}
          >
            <VideoModal
              autoplayHash="play"
              className="mt-4 relative"
              caption={"Why demand?"}
              data={demandDrivesMonetization.extraData}
            />
          </InfoSection>
        )}

        <GlobeBackground>
          <Demand360Globe home={true} className="home" />
        </GlobeBackground>

        {exploreOurProducts && (
          <InfoSection
            id="products"
            className="px-2 py-16 mx-auto max-w-s m:max-w-m l:max-w-l"
            contentBlock={exploreOurProducts}
          >
            <ProductCards
              cardKeys={[
                "content-valuation",
                "demand360",
                "ip-topic-demand",
                "affinity-solutions",
                "supply-metadata",
                "content-panorama",
                "demand360-lite",
              ]}
            />
          </InfoSection>
        )}

        {page?.newsPressArticles?.length > 0 && (
          <NewsPressArticles className="mt-16 l:mt-24 pb-24 l:pb-32">
            <FeaturedPressCarousel
              id="swiper-index-top"
              articles={page.newsPressArticles}
              className="dark"
            />
          </NewsPressArticles>
        )}

        {data?.allTvDemandJson?.nodes[0]?.markets?.length > 1 && (
          <TVDemand showCTA={false} />
        )}

        <div className="mt-16 l:mt-32">
          {page?.featuredTestimonial && (
            <FeaturedTestimonial testimonial={page.featuredTestimonial} />
          )}
        </div>

        {page?.featuredVideo && <FeaturedVideo video={page.featuredVideo} />}

        {featuredWhitepapers.map((cb) => (
          <FeaturedWhitepaper cb={cb} key={cb.id} />
        ))}

        {!!featuredContent &&
          featuredContent.map((cb) => <FeaturedContent cb={cb} key={cb.id} />)}

        {page?.featuredPressArticles?.length > 0 && (
          <NewsPressArticles className="overlapped l:mt-0 pb-24">
            <FeaturedPressCarousel
              id="swipper-index-bottom"
              articles={page.featuredPressArticles}
              className="dark"
            />
          </NewsPressArticles>
        )}
      </PageBackgroundSection>

      <SlantedSection>
        <Demand360Ad className="home" />
      </SlantedSection>

      <LightSlantedSection>
        {page?.articleShowcase?.length > 0 && (
          <div>
            <ArticleShowcase articles={page.articleShowcase} />
          </div>
        )}
      </LightSlantedSection>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query IndexPage {
    gcms {
      page(where: { path: "/" }) {
        ...PageData
        headerContent {
          html
          text
        }
      }
    }
    allTvDemandJson {
      nodes {
        markets {
          country_name
        }
      }
    }
  }
`
